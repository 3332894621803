/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class UserService {
  getUserLists(queryParams) {
    const pars = {
      username: queryParams.username,
      email: queryParams.email,
      name: queryParams.fullname,
      phone: queryParams.phone,
      ip: queryParams.ip,
      affId: queryParams.affId,
      fromDate: queryParams.from_date,
      toDate: queryParams.to_date,
      status: queryParams.stat,
      is_kyc: queryParams.is_kyc,
      referral_code: queryParams.referral_code,
      affiliate_id: queryParams.affiliateId,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance.get("/api/User/index", {
      headers: authHeader(),
      params: pars,
    });
  }

  getUserDetail(userId) {
    return instance.get(`/api/User/detail?userId=${userId}`, {
      headers: authHeader(),
    });
  }

  getUserBalance(userId) {
    return instance.get("/api/Balance/index", {
      headers: authHeader(),
      params: { userId: userId },
    });
  }

  getUserBalanceType() {
    return instance.get("/api/balance-type/index", { headers: authHeader() });
  }

  getUserAdjustmentType() {
    return instance.get("/api/adjust-balance-reason/index", {
      headers: authHeader(),
    });
  }

  getUserAdjustmentStatusOptions() {
    return instance.get("/api/txn-status/index", { headers: authHeader() });
  }

  getProvinces() {
    return instance.get("/api/Province/index", { headers: authHeader() });
  }

  getLevel() {
    return instance.get("/api/Level/index", { headers: authHeader() });
  }
  getEmail(id) {
    return instance.get("/api/User/view-email", {
      headers: authHeader(),
      params: { userId: id },
    });
  }
  getUserPhone(id) {
    return instance.get("/api/User/view-phone", {
      headers: authHeader(),
      params: { userId: id },
    });
  }
  getUserAccountStatement(queryParams) {
    const pars = {
      userId: queryParams.userId,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate,
    };
    return instance.get("/api/User/statement", {
      headers: authHeader(),
      params: pars,
    });
  }

  getUserAuditLogs(queryParams) {
    const pars = {
      userId: queryParams.userId,
      eventTypeId: queryParams.eventType,
      activity: queryParams.activity,
      ip: queryParams.clientIp,
      pageNumber: queryParams.page,
    };
    return instance
      .get("/api/audit-log/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          auditLogs: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }

  getUserAuditLogDetail(auditLog) {
    const pars = {
      auditLogId: auditLog.id,
    };
    return instance.get("/api/audit-log/detail", {
      headers: authHeader(),
      params: pars,
    });
  }

  getUserAuditLogEventType() {
    return instance.get("/api/audit-log/event-type/index", {
      headers: authHeader(),
    });
  }

  addUser(userData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("username", userData.username);
    data.append("password", userData.password);
    data.append("fullName", userData.fullName);
    data.append("email", userData.email);
    data.append("phone", userData.phone);
    data.append("birthday", userData.birthday);
    data.append("roleId", userData.roleId);
    data.append("levelId", userData.levelId);
    return instance.post("/api/User/create", data, { headers: authHeader() });
  }

  updateUser(userData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("userId", userData.userId);
    data.append("username", userData.username);
    data.append("password", userData.password);
    data.append("fullName", userData.fullName);
    data.append("email", userData.email);
    data.append("emailVerify", userData.emailVerify);
    data.append("phone", userData.phone);
    data.append("birthday", userData.birthday);
    data.append("levelId", userData.levelId);
    data.append("affId", userData.affId);
    data.append("stat", userData.stat);
    data.append("currency", userData.currency);
    data.append("note", userData.note);
    // data.append("emailVerify", userData.emailVerify);
    data.append("phone_verified", userData.phone_verified);

    data.append("auto_withdrawal", userData.auto_withdrawal ? "2" : "1");

    return instance.post("/api/User/edit", data, { headers: authHeader() });
  }
  createUserMemberList(userData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("username", userData.username);
    data.append("fullName", userData.fullName);
    data.append("email", userData.email);
    data.append("password", userData.password);
    data.append("phone", userData.phone);
    data.append("birthday", userData.birthday);
    data.append("levelId", userData.levelId);
    data.append("affId", userData.affId);
    data.append("currency", userData.currency);

    return instance.post("/api/User/create", data, { headers: authHeader() });
  }

  updateUserStatus(userData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("userId", userData.userId);
    data.append("status", userData.status);
    data.append("note", userData.note);

    return instance.post("/api/User/update-status", data, {
      headers: authHeader(),
    });
  }
  postMessageUser(userData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("userId", userData.userId);
    data.append("messageTitleId", userData.messageTitleId);
    data.append("textMsg", userData.textMsg);
    data.append("phone", userData.phone);

    return instance.post("/api/User/send-msg", data, {
      headers: authHeader(),
    });
  }
  addMessageAllUser(userData) {
    const FormData = require("form-data");
    const data = new FormData();
    // data.append("userId", userData.userId);
    data.append("messageTitleId", userData.messageTitleId);
    data.append("textMsg", userData.textMsg);
    data.append("phone", userData.phone);

    return instance.post("/api/User/send-msg", data, {
      headers: authHeader(),
    });
  }
  

  changeUserPassword(userData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("userId", userData.userId);
    data.append("password", userData.password);
    data.append("note", userData.note);

    return instance.post("/api/User/change-password", data, {
      headers: authHeader(),
    });
  }

  addUserTag(userTagData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("userId", userTagData.userId);
    data.append("tagTypeId", userTagData.tagTypeId);
    userTagData.tagIds.map((item, index) => {
      data.append(`tagIds[${index}]`, item);
    });

    return instance.post("/api/User/add-tag", data, { headers: authHeader() });
  }

  deleteUserTag(userTagData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("userId", userTagData.userId);
    data.append("userTagId", userTagData.userTagId);

    return instance.post("/api/User/delete-tag", data, {
      headers: authHeader(),
    });
  }
  postCallCenter(userData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("userId", userData.userId);
    return instance.post("/api/call-center/calls", data, {
      headers: authHeader(),
    });
  }

  updateAdvanceUser(userData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("userId", userData.userId);
    // data.append("username", userData.username);
    // data.append("password", userData.password);
    // data.append("fullName", userData.fullName);
    // data.append("email", userData.email);
    data.append("email_verified", userData.email_verified);
    // data.append("phone_verified", userData.phoneVerified);
    // data.append("phone", userData.phone);
    // data.append("birthday", userData.birthday);
    // data.append("levelId", userData.levelId);
    data.append("affiliate_id", userData.affiliate_id);
    // data.append("stat", userData.stat);
    // data.append("currency", userData.currency);
    // data.append("note", userData.note);
    // data.append("emailVerify", userData.emailVerify);
    data.append("phone_verified", userData.phone_verified);

    // data.append("auto_withdrawal", userData.auto_withdrawal ? "2" : "1");

    return instance.post("/api/User/edit-advance", data, {
      headers: authHeader(),
    });
  }

  fetchLogKycUser(queryParams) {
    const pars = {
      userId: queryParams.userId,
      username: queryParams.username,
      name: queryParams.name,
      is_kyc: queryParams.is_kyc,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate,
      pageNumber: queryParams.pageNumber,
    };
    return instance.get("/api/kyc/index", {
      headers: authHeader(),
      params: pars,
    });
  }

  updateUserKycLogStatus(request) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("status", request.status);
    if (request.reason) {
      data.append("reason", request.reason);
    }
    data.append("userKycId", request.id);
    return instance.post("/api/kyc/update-status", data, {
      headers: authHeader(),
    });
  }

  fetchAuditBankAccount(queryParams) {
    const pars = {
      username: queryParams.username,
      bank_account: queryParams.bankAccount,
      pageNumber: queryParams.pageNumber,
    };
    return instance.get("/api/audit/user-bank-account", {
      headers: authHeader(),
      params: pars,
    });
  }

  exportListUser(queryParams) {
    const pars = {
      username: queryParams.username,
      email: queryParams.email,
      name: queryParams.fullname,
      phone: queryParams.phone,
      ip: queryParams.ip,
      level: queryParams.level,
      fromDate: queryParams.from_date,
      toDate: queryParams.to_date,
      status: queryParams.stat,
      is_kyc: queryParams.is_kyc,
      referral_code: queryParams.referral_code,
      affiliate_id: queryParams.affiliateId
    };
    const data = {};
    return instance.post("/api/User/export", data,{
      headers: authHeader(),
      params: pars,
    });
  }

  fetchRefUser(queryParams) {
    const pars = {
      userId: queryParams.userId,
      pageNumber: queryParams.pageNumber,
    };
    return instance.get("/api/User/referral/list", {
      headers: authHeader(),
      params: pars,
    });
  }

}

export default new UserService();
