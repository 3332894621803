import axios from "@axios";
import UserService from "@/libs/user.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        UserService.getUserLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserDetail(ctx, id) {
      return new Promise((resolve, reject) => {
        UserService.getUserDetail(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserBalance(ctx, id) {
      return new Promise((resolve, reject) => {
        UserService.getUserBalance(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserBalanceType(ctx) {
      return new Promise((resolve, reject) => {
        UserService.getUserBalanceType()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserAdjustmentType(ctx) {
      return new Promise((resolve, reject) => {
        UserService.getUserAdjustmentType()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserAdjustmentStatusOptions(ctx) {
      return new Promise((resolve, reject) => {
        UserService.getUserAdjustmentStatusOptions()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserAccountStatement(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        UserService.getUserAccountStatement(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserAuditLogs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        UserService.getUserAuditLogs(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserAuditLogDetail(ctx, auditLog) {
      return new Promise((resolve, reject) => {
        UserService.getUserAuditLogDetail(auditLog)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserAuditLogEventType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        UserService.getUserAuditLogEventType(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        UserService.addUser(userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        UserService.updateUser(userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateUserStatus(ctx, userData) {
      return new Promise((resolve, reject) => {
        UserService.updateUserStatus(userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    postMessageUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        UserService.postMessageUser(userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    postMessageAllUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        UserService.addMessageAllUser(userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    changeUserPassword(ctx, userData) {
      return new Promise((resolve, reject) => {
        UserService.changeUserPassword(userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCallCenter(ctx, userData) {
      return new Promise((resolve, reject) => {
        UserService.postCallCenter(userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchProvinces(ctx) {
      return new Promise((resolve, reject) => {
        UserService.getProvinces()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLevel(ctx) {
      return new Promise((resolve, reject) => {
        UserService.getLevel()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEmail(ctx, id) {
      return new Promise((resolve, reject) => {
        UserService.getEmail(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPhone(ctx, id) {
      return new Promise((resolve, reject) => {
        UserService.getUserPhone(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addUserTag(ctx, userTagData) {
      return new Promise((resolve, reject) => {
        UserService.addUserTag(userTagData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteUserTag(ctx, userTagData) {
      return new Promise((resolve, reject) => {
        UserService.deleteUserTag(userTagData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addUserMemberList(ctx, id) {
      return new Promise((resolve, reject) => {
        UserService.createUserMemberList(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateAdvanceUser(ctx, id) {
      return new Promise((resolve, reject) => {
        UserService.updateAdvanceUser(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLogKycUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        UserService.fetchLogKycUser(queryParams)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
    updateUserKycLogStatus(ctx, bonusData) {
      return new Promise((resolve, reject) => {
        UserService.updateUserKycLogStatus(bonusData)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
    exportListUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        UserService.exportListUser(queryParams)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
    fetchAuditBankAccount(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        UserService.fetchAuditBankAccount(queryParams)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
    fetchRefUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        UserService.fetchRefUser(queryParams)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
  },
};
