<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="4" lg="4" sm="6" class="mb-2 ipad">
            <b-form-group label="Username" label-for="username" class="mb-0">
              <b-form-input
                  id="username"
                  v-model="search.username"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" lg="4" sm="6" class="mb-2 ipad">
            <b-form-group label="Fullname" label-for="fullname" class="mb-0">
              <b-form-input
                  id="fullname"
                  v-model="fullnameFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" lg="4" sm="6" class="mb-2 ipad">
            <label>{{ $t("Status") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="isKycFilter"
              :options="kycOptions"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-col>

          <b-col cols="12" md="4" lg="4" sm="6" class="mb-0 ipad">
            <b-form-group :label="$t('From date')" label-for="fromDate">
              <flat-pickr
                  id="fromDate"
                  v-model="search.fromdate"
                  class="form-control"
                  placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" lg="4" sm="6" class="mb-0 ipad">
            <b-form-group :label="$t('To date')" label-for="toDate">
              <flat-pickr
                  id="toDate"
                  v-model="search.todate"
                  class="form-control"
                  placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-end justify-content-end">
          <b-col
              cols="12"
              md="4"
              class="d-flex align-items-end justify-content-end"
          >
            <b-button variant="outline-primary" @click="resetFilter()">
              Reset
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              Search
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <b-table
          ref="refLogKycListTable"
          class="position-relative table-white-space mb-0 max-height-table"
          sticky-header
          head-variant="light"
          :items="logKycList"
          responsive
          :fields="tableColumns"
          primary-key="index"
          show-empty
          empty-text="No matching records found"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>
        <template #cell(front_document)="data">
          <b-link
              v-if="data.item.front_document"  
              :href="baseUrl + data.item.front_document.file_path"
              target="_blank"
              class="cursor-pointer"
          >
            <b-img
                :src="`${data.item.front_document.file_path ? baseUrl + data.item.front_document.file_path : ''}`"
                fluid
                style="max-width: 100px;"
                class="mr-2 rounded img-fluid bg-white"
            />
          </b-link>
        </template>

        <template #cell(back_document)="data">
          <b-link
              v-if="data.item.back_document"
              :href="baseUrl + data.item.back_document.file_path"
              target="_blank"
              class="cursor-pointer"
          >
            <b-img
                :src="`${data.item.back_document.file_path ? baseUrl + data.item.back_document.file_path : ''}`"
                fluid
                style="max-width: 100px;"
                class="mr-2 rounded img-fluid bg-white"
            />
          </b-link>
        </template>

        <template #cell(selfie)="data">
          <b-link
              v-if="data.item.selfie"
              :href="baseUrl + data.item.selfie.file_path"
              target="_blank"
              class="cursor-pointer"
          >
            <b-img
                :src="`${data.item.selfie.file_path ? baseUrl + data.item.selfie.file_path : ''}`"
                fluid
                style="max-width: 100px;"
                class="mr-2 rounded img-fluid bg-white"
            />
          </b-link>
        </template>
        <template #cell(status)="data">
          <span v-if="data.item.status === 1" class="text-warning">
            Pending
          </span>
          <span v-if="data.item.status === 2" class="text-success">
            Success
          </span>
          <span v-if="data.item.status === 3" class="text-secondary">
            Reject
          </span>
        </template>
        <template #cell(action)="data">
          <b-link
              v-b-tooltip.hover.v-info
              :title="$t('Edit')"
              class="font-weight-bold text-nowrap mr-1"
              style="cursor: pointer"
              @click="showEditKycModal(data.item)"
              v-if="data.item.status === 1 && $can('update', 'userkycstatus')"
          >
            Edit
          </b-link>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalLogKyc"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Edit User Bonus Request Modal -->
    <edit-user-log-kyc-modal
        :user-kyc-log.sync="userLogKyc"
        @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BImg, VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {ref, watch, computed, onUnmounted} from "@vue/composition-api";
import {formatDateTime, numberFormat} from "@core/utils/filter";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import flatPickr from "vue-flatpickr-component";
import userStoreModule from "@/views/apps/user/userStoreModule";
import EditUserLogKycModal from "@/views/apps/user/users-kyc/EditUserLogKycModal";

export default {
  components: {
    EditUserLogKycModal,
    flatPickr,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      userLogKyc: null,
    };
  },
  methods: {
    showEditKycModal(data) {
      this.userLogKyc = data;
      this.$bvModal.show("modal-edit-user-log-kyc");
    },
  },
  setup() {
    if (!store.hasModule("user"))
      store.registerModule("user", userStoreModule);
    onUnmounted(() => {
      if (store.hasModule("user")) store.unregisterModule("user");
    });

    const search = ref({
      username: "",
      fromdate: "",
      todate: "",
    });
    const usernameFilter = ref(null);
    const fullnameFilter = ref(null);
    const isKycFilter = ref(null);
    const sportTypeId = ref([]);
    const toDateFilter = ref(null);
    const fromDateFilter = ref(null);
    const logKycList = ref([]);
    const perPage = ref(25);
    const totalLogKyc = ref(0);
    const currentPage = ref(1);

    const refLogKycListTable = ref(null);
    const tableColumns = [
      {key: "index", label: "#", sortable: false},
      {key: "username", label: "Username"},
      {key: "name", label: "Fullname"},
      {key: "dob", label: "DoB"},
      {key: "front_document", label: "Ảnh mặt trước"},
      {key: "back_document", label: "Ảnh mặt sau"},
      {key: "selfie", label: "Ảnh selfie"},
      {key: "status", label: "Trạng thái"},
      {key: "reason", label: "Lý do"},
      {key: "created_at", label: "Created at", sortable: true},
      {key: "updated_by", label: "Updated by"},
      {key: "updated_at", label: "Updated at", sortable: true},
      {key: "action", label: "Action"},
    ];

    const refetchData = () => {
      refLogKycListTable.value.refresh();
      sportTeamLists();
    };

    watch(
        [currentPage],
        () => {
          refetchData();
        }
    );
    const transformedArray = ref();
    const sportTeamLists = (ctx, callback) => {
      store
          .dispatch("user/fetchLogKycUser", {
            username: usernameFilter.value,
            name: fullnameFilter.value,
            is_kyc: isKycFilter.value,
            fromDate: fromDateFilter.value,
            toDate: toDateFilter.value,
            pageNumber: currentPage.value,
          })
          .then((response) => {
            logKycList.value = response.data.data.map((val, index) => {
              let front_document, back_document, selfie;
              val.user_kyc_logs.map((kyc) => {
                if (kyc.type === 1) {
                  front_document = kyc
                } else if (kyc.type === 2) {
                  back_document = kyc
                } else {
                  selfie = kyc
                }
              })
              return {
                ...val,
                index: index + 1,
                username: val.user.username,
                front_document: front_document,
                back_document: back_document,
                selfie: selfie,
              };
            });
            totalLogKyc.value = response.data.count;
          });
    };

    store.dispatch("user/fetchLogKycUser", {
      username: usernameFilter.value,
      name: fullnameFilter.value,
      is_kyc: isKycFilter.value,
      fromDate: fromDateFilter.value,
      toDate: toDateFilter.value,
      pageNumber: currentPage.value,
    }).then((response) => {
      logKycList.value = response.data.data.map((val, index) => {
        let front_document, back_document, selfie;
        val.user_kyc_logs.map((kyc) => {
          if (kyc.type === 1) {
            front_document = kyc
          } else if (kyc.type === 2) {
            back_document = kyc
          } else {
            selfie = kyc
          }
        })
        return {
          ...val,
          index: index + 1,
          username: val.user.username,
          front_document: front_document,
          back_document: back_document,
          selfie: selfie,
        };
      });
      totalLogKyc.value = response.data.count;
    });

    const dataMeta = computed(() => {
      const localItemsCount = refLogKycListTable.value
          ? refLogKycListTable.value.localItems.length
          : 0;
      return {
        from:
            perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalLogKyc.value,
      };
    });

    const resetFilter = () => {
      search.value.fromdate = "";
      search.value.todate = "";
      search.value.username = "";
      toDateFilter.value = null;
      fromDateFilter.value = null;
      usernameFilter.value = null;
      fullnameFilter.value = null;
      isKycFilter.value = null;
      sportTeamLists();
    };

    const searchFilter = () => {
      fromDateFilter.value = search.value.fromdate;
      toDateFilter.value = search.value.todate;
      usernameFilter.value = search.value.username;
      sportTeamLists();
    };

    const kycOptions = [
      { label: 'Pending', value: 1 },
      { label: 'Success', value: 2 },
      { label: 'Reject', value: 3 },
    ]

    return {
      logKycList,
      tableColumns,
      perPage,
      currentPage,
      totalLogKyc,
      dataMeta,
      refLogKycListTable,
      sportTeamLists,
      refetchData,
      toDateFilter,
      isKycFilter,
      search,
      resetFilter,
      numberFormat,
      formatDateTime,
      searchFilter,
      baseUrl,
      sportTypeId,
      fromDateFilter,
      transformedArray,
      usernameFilter,
      fullnameFilter,
      kycOptions
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
